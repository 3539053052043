var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__overlay" }, [
    _c("div", { staticClass: "popup__box" }, [
      _c("img", {
        staticClass: "popup__img",
        attrs: { src: require("@/assets/404.svg"), alt: "" }
      }),
      _c("div", { staticClass: "popup__title" }, [_vm._v("404 Error")]),
      _c("div", { staticClass: "popup__subtitle" }, [
        _vm._v("哎呀...暫時找不到您訪問的星球！")
      ]),
      _c("div", { staticClass: "popup__btn", on: { click: _vm.toIndex } }, [
        _vm._v("返回")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }