<template>
  <div class="popup__overlay">
    <div class="popup__box">
      <img class="popup__img" src="@/assets/404.svg" alt />
      <div class="popup__title">404 Error</div>
      <div class="popup__subtitle">哎呀...暫時找不到您訪問的星球！</div>

      <div class="popup__btn" @click="toIndex">返回</div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    toIndex() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/scss/popup.scss";
</style>
